import React from 'react'

import { Header } from 'semantic-ui-react'

export function parseCF(item) {
  
  // console.log('parseCF', item)
  switch (item.nodeType) {
    case "heading-1":
      return <h1>{ item.content[0].value }</h1>
    case "heading-2":
      return <h2>{ item.content[0].value }</h2>
    case "heading-3":
      return <Header as='h3' textAlign='center'>{ item.content[0].value }</Header>
    case "heading-4":
      return <h4>{ item.content[0].value }</h4>
    case "heading-5":
      return <h5>{ item.content[0].value }</h5>
    case "heading-6":
      return <h6>{ item.content[0].value }</h6>
    case "paragraph":
      return <p>{ item.content[0].value }</p>
    case "ordered-list":
      return (
        <ol>
          { item.content.map(( li, i ) => (
            <li>{ li.content[0].content[0].value }</li>
          ))}
      </ol> )
    case "unordered-list":
      return (
        <ul>
          { item.content.map(( li, i ) => (
            <li>{ li.content[0].content[0].value }</li>
          ))}
      </ul> )
    default:
      return (
        <div></div>
      )
  } 
}

export const titleCase = str => {

  if (( str === null ) || ( str === '' ))

    return false

  else

    str = str.toString();

    let dashWithSpace = new RegExp(/[\-]\s/g)
    let dashNoSpace = new RegExp(/-(?!\s)/g)

    !dashWithSpace.test(str) && dashNoSpace.test(str) ?
  
      str = str.replace(dashNoSpace, ' ')
        :
      str = str.replace(dashNoSpace, ' ')

      return str.replace(/\w\S*/i, (txt) => {

        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      
      })
}