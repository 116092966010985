import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import * as Helpers from '../components/Utilities/helpers'

function SEO({ description, lang, meta, keywords, title, addKeywords }) {
  return (
    <StaticQuery
      query={ graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
              keywords
              siteUrl
            }
          }
        }
      `}
      render={data => {
        const { siteMetadata } = data.site
        const metaDescription = description || siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={ Helpers.titleCase(title) }
            titleTemplate={`%s | ${siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: `${Helpers.titleCase(title)} | ${siteMetadata.title}`,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:locale`,
                content: `pl_PL`,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: siteMetadata.siteUrl,
              },
              {
                name: `twitter:card`,
                content: metaDescription,
              },
              {
                name: `twitter:creator`,
                content: siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: `${Helpers.titleCase(title)} | ${siteMetadata.title}`,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : siteMetadata.keywords.length > 0
                    ? {
                      name: `keywords`,
                      content: addKeywords.length > 0 ?
                        siteMetadata.keywords.concat(addKeywords).join(`, `)
                        : siteMetadata.keywords.join(`, `),
                    }
                    : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `pl-Pl`,
  meta: [],
  keywords: [],
  addKeywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  addKeywords: PropTypes.arrayOf(PropTypes.string)
}

export default SEO